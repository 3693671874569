var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { attrs: { "id": "gdpr_consent" } }, [_c("VueModal", { staticClass: "gdpr-modal", attrs: { "modal-id": "gdpr_modal", "open": !_vm.hasGdpr, "role": "dialog", "closable": _vm.closable, "close-btn-visible": false } }, [_c("h2", { staticClass: "sf-heading-1 gdpr-modal-heading1", attrs: { "slot": "modal-header", "id": "gdpr_modal", "tabindex": "-1" }, slot: "modal-header" }, [_vm.hasDetails ? [_vm._v(_vm._s(_vm.$t("title-details")))] : [_vm._v(_vm._s(_vm.$t("title")))]], 2), _c("gdpr-modal-content", { ref: "gdprModalContent", attrs: { "slot": "modal-body", "type": _vm.type, "actual-gdpr-level": Number(_vm.gdprLevel), "details": _vm.details, "disclaimer-url": _vm.disclaimerUrl, "site-name": _vm.siteName, "main-desc-prefix": _vm.mainDescPrefix }, on: { "show-details": _vm.setModalTitle }, slot: "modal-body" }, [_vm._t("level1List", null, { "slot": "level1List" }), _vm._t("level2List", null, { "slot": "level2List" }), _vm._t("level3List", null, { "slot": "level3List" }), _c("VueButton", { on: { "click": function($event) {
    return _vm.$changeModalWidth("modal-id", "narrow");
  } } }, [_vm._v("Modal Change")]), _c("VueButton", { on: { "click": function($event) {
    return _vm.$hideModalHeader("modal-id");
  } } }, [_vm._v("Modal Hide Header")]), _c("VueButton", { on: { "click": function($event) {
    return _vm.$showModalHeader("modal-id");
  } } }, [_vm._v("Modal Show Header")])], 2), _c("VueButton", { on: { "click": function($event) {
    return _vm.$openModal($event, "modal-id");
  } } }, [_vm._v("Modal Open")])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
